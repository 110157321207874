import React from 'react';
import { navigate } from 'gatsby';
import { isLoggedIn } from "../services/auth"

const AllAccessRoute = ({ component: Component, location, prevLocation, ...rest }) => {
  
  // useEffect(() => {
  //   checkLoginStatus()
  // }, [])
  
  // const checkLoginStatus = () => {
  // if (!isLoggedIn() & (location.pathname !== `/all-access/login`)) {
  //   navigate("/all-access/login")
  //   return null
  // }
  // }

  if (!isLoggedIn() & (location.pathname !== `/all-access/login`)) {
    if ( prevLocation.includes("/all-access/startups/") ) {
      navigate("/all-access/login",
      {
        state: { "prevLocation": prevLocation },
      })
    } else {
      navigate("/all-access/login")
    }
    return null
  }

  return <Component {...rest} />
}

export default AllAccessRoute;