import React from "react"
import StartupCharacteristics from '../components/profile-startup';
import { css } from '@emotion/core';
import { color } from '../components/defaults/styles/theme';
import { TitleHeader, wrapper } from '../components/defaults/styles/elements';
import { People } from "../components/defaults/assets/svg/mobilenav"
import Video from "../components/defaults/assets/svg/video.svg"
import ProfileUsers from '../components/main/profile-users';
import RearviewInline, { ifRearview } from '../components/main/rearviewInline';
import ProfileYoutube from '../components/main/profile-youtube';
import Play from "../components/defaults/assets/svg/play.svg"


const StartupProfile = ({data, recordId}) => {
    // console.log(ifRearview(data.rearview))
    return (
      <>
        <StartupCharacteristics data={data} id={recordId} />
        {data.pilot_id && data.pilot_id.length > 0 ? (
          <ProfileYoutube
            icon={<Play />}
            title="Pilot Video"
            videoId={data.pilot_id}
            speakers={data.pilot_speaker}
          />
        ) : (
          ""
        )}
        <section
          css={css`
            background: ${color.main_light};
            padding-top: 5em;
            padding-bottom: 5em;
          `}
        >
          {data.users && data.users !== undefined && data.users.length !== 0 ? (
            <div css={css`
              margin-bottom: 5em;
            `}>
              <TitleHeader
                title={`Employees of ` + data.startup}
                icon={<People />}
                css={css`
                  padding-top: 0;
                  padding-bottom: 1em;
                `}
              />
              <ProfileUsers
                data={data.users}
                css={css`
                  padding-top: 0;
                  padding-bottom: 0;
                `}
              />
            </div>
          ) : (
            ""
          )}
          {data.rearview &&
          data.rearview !== undefined &&
          data.rearview.length > 0 &&
          ifRearview(data.rearview) ? (
            <div css={[wrapper, css``]}>
              <TitleHeader
                title={`Rearview clips by ` + data.startup}
                inline
                icon={<Video />}
                css={css`
                  padding-top: 0;
                  padding-bottom: 1em;
                `}
              />
              <RearviewInline data={data.rearview} />
            </div>
          ) : (
            ""
          )}
        </section>
      </>
    )
}

export default StartupProfile;