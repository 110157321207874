import React, { useState } from 'react';
import { navigate } from 'gatsby';
import { handleLogin, isLoggedIn, isBrowser } from '../services/auth';
import { css } from '@emotion/core';
import Cleave from "cleave.js/react"
import { color } from './defaults/styles/theme';
import LoginIcon from '../components/defaults/assets/svg/login.svg';
import Warning from '../components/defaults/assets/svg/warning.svg';
import Lock from '../components/defaults/assets/svg/lock.svg';
import { TitleHeader, NotificationBar } from '../components/defaults/styles/elements';
import mq from '../components/defaults/styles/mediaquerys';


const Login = ({location}) => {

  const [state, setState] = useState({
    accesscode: ``,
  })

  const [consent, setConsent] = useState(false)
  const [warning, setWarning] = useState(false)
  const [wrongPassword, setWrongPassword] = useState(false)

  const handleWarningChange = () => {
    setWarning(!warning)
  }
  const handleWrongPasswordChange = () => {
    setWrongPassword(!wrongPassword)
  }

  const handleUpdate = (event) => {
    let stateCopy = state;
    stateCopy[event.target.name] = event.target.rawValue;
    setState(stateCopy);
  }

  const handleCheckbox = () => {
    setConsent(!consent)
    setWarning(false)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    // let accesscode = Object.values(state).join("-")
    if (consent === true) {
      handleLogin(state)
      if (isLoggedIn()) {
        if (location.state.prevLocation && location.state.prevLocation.includes("/all-access/startups/") ) {
          navigate(location.state.prevLocation)
        } else {
          navigate(`/startups/`)
        }
      } else {
        setWrongPassword(true)
      }
    } else {
      setWarning(true)
    }
  }

  if (isLoggedIn()) {
    navigate(`/startups/`)
  }
  
  return (
    <>
      {!consent && warning ? (
        <NotificationBar 
          title="Attention"
          content="<p>You need to consent to the statement below in order to proceed to the All Access Area.</p>"
          icon={<Warning />}
          action_el={handleWarningChange}
          css={css`
            background: ${color.red};
            color: white;
            .icon {
              background: white;
              svg {
                stroke: ${color.main_dark};
                stroke-width: 2.5px;
              }
            }
            .title {
              color: white;
            }
        `}/>
      ) : ''}
      {wrongPassword ? (
        <NotificationBar 
          title="Attention"
          content="<p>You entered a wrong passphrase.</p><p>Please try again or reach out to your champion or Hannah Boomgarden (<a href='mailto:hannah@pnptc.com'>hannah@pnptc.com</a>) to receive a working unlock code.</p>"
          icon={<Warning />}
          action_el={handleWrongPasswordChange}
          css={css`
            background: ${color.red};
            color: white;
            .icon {
              background: white;
              svg {
                stroke: ${color.main_dark};
                stroke-width: 2.5px;
              }
            }
            .title, a {
              color: white;
            }
        `}/>
      ) : ''}
      <section
        css={css`
          display: flex;
          background: ${color.main_light};
          align-items: center;
          justify-content: center;
          padding: 2em 1em;
          margin-top: 5.2rem;
          min-height: ${ isBrowser() ? window.innerHeight - 15 * 16 + "px" : ""};
          flex-direction: column;
          ${mq[2]} {
            padding: 5em 0;
          }
        `}
      >
        <div
          css={css`
            margin: 0rem 0;
            background: white;
            padding: 2rem;
            border-radius: 5px;
            max-width: 30rem;
            position: relative;
            min-height: 45vh;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            &:after {
              content: "";
              background: ${color.main_dark};
              bottom: 0;
              left: 0;
              height: 1px;
              width: 100%;
              position: absolute;
              opacity: 0.18;
            }
          `}
        >
          <TitleHeader
            title="All Access Area"
            icon={<Lock />}
            subtitle="<p>Welcome, we are glad you’ve joined us. This space is reserved for you: our corporate partners, program startups, press and media partners, and special guests.</p><p>Enjoy the content, but to align with data regulations, please don’t share information you discover here with the outside world.</p>"
            transparent
            fullWidth
            inline
            css={css`
              padding: 0;
            `}
          />
          <form
            method="post"
            onSubmit={event => {
              handleSubmit(event)
            }}
            css={css`
              display: flex;
              flex-direction: column;
              align-items: center;
              margin: 3rem 0 0;
            `}
          >
            <div css={css`
              display: flex;
              align-items: flex-start;
              margin-bottom: 1em;
              p {
                font-size: .65em;
                margin-bottom: .5em;
              }
            `}>
              <label htmlFor="consentcheckbox" css={css`
                display: none;
              `}>Label</label>
              <input type="checkbox" id="consentcheckbox" name="checkbox" aria-label="consent checkbox" onClick={handleCheckbox} css={css`
                  height: 1em;
                  width:2em;
                `} />
              <div css={css`
                margin-left: 1em;
                p {
                  color: ${!warning ? color.main_dark : color.red}
                }
              `}>
                <p>I hereby confirm that I do have received the Access Code directly from a STARTUP AUTOBAHN powered by Plug and Play authorized organization.</p><p>I understand that any information listed in this section is not intended for public audiences and sharing of this publication is therefore prohibited.</p>
              </div>
            </div>
            <label
              htmlFor="accesscodeinput"
              css={css`
                display: flex;
                align-items: center;
                width: 100%;
                p {
                  margin: 0;
                }
              `}
            >
              <Cleave
                placeholder="Enter Access Code"
                options={{
                  blocks: [5, 5, 5],
                  delimiter: " – ",
                }}
                id="accesscodeinput"
                name="accesscode"
                autoComplete="off"
                onChange={event => handleUpdate(event)}
                css={css`
                  border-radius: 5px;
                  border: none;
                  text-align: center;
                  font-family: monospace;
                  background: ${color.accent_light};
                  font-size: 1.5em;
                  height: 2.5em;
                  outline: none;
                  font-weight: 500;
                  width: 100%;
                `}
              />
            </label>
            <button
              type="submit"
              css={css`
                color: ${color.main_dark};
                text-decoration: none;
                font-size: 1rem;
                font-weight: 500;
                position: relative;
                padding: 0.375rem 1rem;
                border: none;
                background: none;
                display: flex;
                outline: none;
                align-items: center;
                margin-top: 2em;
                z-index: 1;
                cursor: ${consent ? "pointer" : "default"};
                svg {
                  height: 0.9em;
                  width: auto;
                  margin-right: 0.5rem;
                  fill: none;
                  stroke: ${color.main_dark};
                }
                &:before {
                  content: "";
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  left: 0;
                  top: 0;
                  border-radius: 5px;
                  background: ${consent ? color.cta_green : color.accent_light};
                  z-index: -1;
                }
              `}
            >
              <LoginIcon />
              Log in
            </button>
          </form>
        </div>
        <div css={css`
          max-width: 30rem;
          padding: .5em 2em;
          opacity: .5;
          p, a {
            font-size: .65rem;
            text-align: center;
            color: ${color.main_dark};
            text-decoration: none;
          }
        `}>
          <p>If you categorize yourself into one of the groups above you might qualify for an <b>All Access Pass</b>. If you have not received one please contact your champion or Hannah (<a href="mailto:hannah@pnptc.com">hannah@pnptc.com</a>) for more information and an unlock code.</p>
        </div>
      </section>
    </>
  )
}

export default Login;