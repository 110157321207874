import React from "react"
import StartupProfile from "../components/startup"
import Layout from "../components/layout"
import { Router } from "@reach/router"
import { graphql, navigate } from "gatsby"
import AllAccessRoute from '../components/allaccessRoute';
import Login from '../components/login';
import { isBrowser } from '../services/auth';
import PressFooter from '../components/pressfooter';
import { color } from '../components/defaults/styles/theme';

const AllAccess = ({data, location}) => {
    const startup = data.allAirtable.edges

    const NotFound = () => {
      if (!isBrowser()) return false
      
      navigate("/404")
      return null
    }

    return (
      <Layout
        dark={location.pathname.includes("/all-access/startups") ? true : false}
      >
        <Router>
          <NotFound default />
          {startup.map(({ node }, index) => (
            <AllAccessRoute
              path={"/all-access/startups/" + node.id}
              data={node.data}
              id={node.id}
              recordId={node.recordId}
              key={index}
              component={StartupProfile}
              prevLocation={location.pathname}
            />
          ))}
          <Login path="/all-access/login" image={data.file} />
        </Router>
        <PressFooter theme={color.main_light} />
      </Layout>
    )
}

export const query = graphql`
         {
           allAirtable(
             filter: {
               table: { eq: "startups" }
               data: { include: { eq: true } }
             }
           ) {
             edges {
               node {
                 id
                 data {
                   startup
                   display_company
                   vertical
                   startup_program
                   country
                   city
                   founded
                   number_employees
                   number_pilots
                   stage
                   users {
                     id
                     data {
                       name
                       profession
                       image {
                         localFiles {
                           childImageSharp {
                             fluid {
                               ...GatsbyImageSharpFluid
                             }
                           }
                         }
                       }
                     }
                   }
                   rearview {
                     recordId
                     data {
                       sort
                       publish
                       rearview_tags
                       rearview_thumbnail {
                         localFiles {
                           childImageSharp {
                             fluid {
                               ...GatsbyImageSharpFluid_withWebp_tracedSVG
                             }
                           }
                         }
                       }
                       rearview_description {
                         childMarkdownRemark {
                           excerpt(format: PLAIN, pruneLength: 240)
                         }
                       }
                       rearview_release
                       rearview_title
                       rearview_partners {
                         data {
                           name
                         }
                       }
                       rearview_startups {
                         data {
                           startup
                         }
                       }
                       rearview_agenda {
                         data {
                           agenda_start_time
                         }
                       }
                     }
                   }
                   description {
                     childMarkdownRemark {
                       html
                     }
                   }
                   url
                   twitter
                   linkedin
                 }
                 recordId
               }
             }
           }
           file(relativePath: { eq: "20200812-reach.png" }) {
             childImageSharp {
               fluid {
                 ...GatsbyImageSharpFluid
               }
             }
           }
         }
       `

export default AllAccess;